// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
}

:root {
  --black: #000000;
  --white: #ffffff;
  --yellow: #fae80a;
  --swiper-theme-color: #fae80a !important;
}
.swiper-pagination-bullet .swiper-pagination-bullet-active {
  background: #fae80a !important;
}
.swiper-pagination-bullet-active {
  color: #fff;
  background: #fae80a !important;
}

a {
  text-decoration: none;
  color: var(--black);
}

.truncate {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  /* padding-right: 10px; */
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,wCAAwC;AAC1C;AACA;EACE,8BAA8B;AAChC;AACA;EACE,WAAW;EACX,8BAA8B;AAChC;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;EAC9B,2BAA2B;EAC3B,kCAAkC;EAClC,yBAAyB;AAC3B","sourcesContent":["* {\n  box-sizing: border-box;\n}\n\n:root {\n  --black: #000000;\n  --white: #ffffff;\n  --yellow: #fae80a;\n  --swiper-theme-color: #fae80a !important;\n}\n.swiper-pagination-bullet .swiper-pagination-bullet-active {\n  background: #fae80a !important;\n}\n.swiper-pagination-bullet-active {\n  color: #fff;\n  background: #fae80a !important;\n}\n\na {\n  text-decoration: none;\n  color: var(--black);\n}\n\n.truncate {\n  white-space: nowrap !important;\n  overflow: hidden !important;\n  text-overflow: ellipsis !important;\n  /* padding-right: 10px; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
