export const truncateTitle = (title, length) => {
  return title?.length > length ? title.substring(0, length) + '...' : title;
};

export function formatCount(count) {
  if (count >= 1000000) {
    return (count / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'; // Millions
  } else if (count >= 1000) {
    return (count / 1000).toFixed(1).replace(/\.0$/, '') + 'K'; // Thousands
  }
  return count.toString(); // If less than 1000, return the number as is
};
