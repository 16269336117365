import React from 'react';
import BottomDrawer from '../BottomDrawer/BottomDrawer';
import { IconButton, Stack, Typography } from '@mui/material';
import HeartIcon from '../../assets/icons/giftIcons/HeartIcon';
import CoinIcon from '../../assets/icons/giftIcons/CoinIcon';
import RankingIcon from '../../assets/icons/giftIcons/RankingIcon';
import { useMutation } from '@tanstack/react-query';
import api from '../../config/api';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';

const sendGift = async ({ streamerId, giftType }) => {
  await api.post('api/stream/gift', { streamerId, [giftType]: 1 });
};

const SendGiftDrawer = ({ streamer, showGiftDrawer, setShowGiftDrawer }) => {
  const [selectedGift, setSelectedGift] = React.useState({ streamerId: '', giftType: '' });
  const { mutate: sendGiftMutate } = useMutation({
    mutationFn: sendGift,
    onError: (error) => {
      console.log(error);
      toast.error('Failed to send gift');
    },
    onSuccess: () => {
      toast.success('Gift sent successfully');
      setShowGiftDrawer(false);
      setSelectedGift({ streamerId: '', giftType: '' });
    },
  });
  return (
    <BottomDrawer bottomDrawerState={showGiftDrawer} setBottomDrawerState={setShowGiftDrawer}>
      <Stack spacing={3} sx={{ maxHeight: '20rem', overflowY: 'auto' }}>
        <Typography variant='h5_bold' sx={{ textAlign: 'center' }}>
          Send a Gift
        </Typography>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <IconButton
            sx={{ backgroundColor: selectedGift.giftType === 'hearts' ? 'rgba(0, 0, 0, 0.08)' : 'transparent' }}
            onClick={() => setSelectedGift({ streamerId: streamer?._id, giftType: 'hearts' })}
          >
            <HeartIcon size={60} />
          </IconButton>
          <IconButton
            sx={{ backgroundColor: selectedGift.giftType === 'coins' ? 'rgba(0, 0, 0, 0.08)' : 'transparent' }}
            onClick={() => setSelectedGift({ streamerId: streamer?._id, giftType: 'coins' })}
          >
            <CoinIcon size={60} />
          </IconButton>
          <IconButton
            sx={{ backgroundColor: selectedGift.giftType === 'stars' ? 'rgba(0, 0, 0, 0.08)' : 'transparent' }}
            onClick={() => setSelectedGift({ streamerId: streamer?._id, giftType: 'stars' })}
          >
            <RankingIcon size={60} />
          </IconButton>
        </Stack>
        <Button
          variant='contained'
          onClick={() => sendGiftMutate(selectedGift)}
          disabled={selectedGift.giftType === ''}
        >
          Send {selectedGift.giftType}
        </Button>
      </Stack>
    </BottomDrawer>
  );
};

export default SendGiftDrawer;
