import React from 'react';
import Card from '@mui/material/Card';
import { Box, IconButton } from '@mui/material';
import Stack from '@mui/material/Stack';
import EyeIcon from '../../../../assets/icons/EyeIcon';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CustomAvatar from '../../../../components/CustomAvatar/CustomAvatar';
import PlayIcon from '../../../../assets/icons/PlayIcon';
// import useGetThumbnail from '../../../../hooks/useGetThumbnail';
import ROUTES from '../../../../routes';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import api from '../../../../config/api';

const getRecording = async (streamId) => {
  const { data } = await api.get(`/api/stream/recordings/${streamId}`);
  return data;
};

const RecordedStreamCard = ({ data, isLoading, isFetching, hasNextPage, fetchNextPage, index, totalCount }) => {
  const navigate = useNavigate();
  const streamId = data.streamDetails.liveStreamId;
  const { data: recording } = useQuery({
    queryKey: ['recording', streamId],
    queryFn: () => getRecording(streamId),
    refetchOnWindowFocus: false,
  });
  const taskId = data?._id;
  const handleLiveStream = () => {
    navigate(ROUTES.RECORDING_PLAY + '?streamId=' + streamId + '&taskId=' + taskId);
  };
  const username = data.type === 'REQUEST' ? data?.accepted?.user?.name : data?.user?.name;
  const avatarUrl = data.type === 'REQUEST' ? data?.accepted?.user?.profile?.avatarUrl : data?.user?.profile?.avatarUrl;

  return (
    <Card sx={{ width: '100%', borderRadius: '20px', mb: 1, m: 1 }}>
      <Box
        sx={{
          height: 200,
          // backgroundImage: `url(${thumbnail?.data?.live_stream?.thumbnail_url})`,
          backgroundImage: `url(${recording?.data?.asset[0]?.asset?.thumbnail_url})`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundColor: '#c1c1c1',
          position: 'relative',
          cursor: 'pointer',
        }}
        onClick={handleLiveStream}
      >
        <Stack spacing={0.5} direction='row' sx={{ position: 'absolute', top: 16, right: 16 }}>
          <Stack
            spacing={0.5}
            direction='row'
            sx={{
              padding: '4px 8px 4px 8px',
              backgroundColor: '#5F5D5D80',
              color: '#fff',
              borderRadius: '4px',
            }}
          >
            <EyeIcon />
            <Typography variant='caption_bold'>{data?.viewers}</Typography>
          </Stack>
          {/*<Stack*/}
          {/*  spacing={0.5}*/}
          {/*  direction='row'*/}
          {/*  alignItems='center'*/}
          {/*  sx={{*/}
          {/*    padding: '4px 8px 4px 8px',*/}
          {/*    backgroundColor: '#fff',*/}
          {/*    color: '#000',*/}
          {/*    borderRadius: '4px',*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Box*/}
          {/*    sx={{*/}
          {/*      width: 7,*/}
          {/*      height: 7,*/}
          {/*      backgroundColor: '#F11B45',*/}
          {/*      borderRadius: '50%',*/}
          {/*    }}*/}
          {/*  ></Box>*/}
          {/*  <Typography variant='caption_bold'>Live</Typography>*/}
          {/*</Stack>*/}
        </Stack>
        <Typography variant='h6_bold' sx={{ position: 'absolute', left: 20, bottom: 20, color: '#fff' }}>
          {data.title}
        </Typography>
      </Box>
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack width={'80%'} spacing={1} direction='row' alignItems='center'>
          <CustomAvatar avatarUrl={avatarUrl} name={username} />
          <Typography variant='h5_bold' className='truncate'>{username}</Typography>
          {/* <Avatar>C</Avatar> */}
          {/* <Box>
            <Typography variant='h5_bold'>{username}</Typography> */}
            {/*<Typography variant='h6' color='#737373'>*/}
            {/*  {data.creator.subTitle}*/}
            {/*</Typography>*/}
          {/* </Box> */}
        </Stack>
        <IconButton
          color='primary'
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            '&:hover': {
              backgroundColor: (theme) => theme.palette.primary.main,
            },
          }}
          onClick={handleLiveStream}
        >
          <PlayIcon />
        </IconButton>
      </CardContent>
    </Card>
  );
};

export default RecordedStreamCard;
